import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { unionBy } from 'lodash'
import { Stack } from '@mui/material'

import useTileQuery from '../../hooks/useTileQuery'
import { TYPES_OF_TILE } from '../../../../../constants'
import CheckboxList from 'components/pages/dis-v2/_components/CheckboxList'

function TextSearchForm(props) {
	const {
		dashboardKey,
		filterData,
		enabledFetchTileList = true,
		datagridListWhenCreating = [],
		onFilterDataChange,
	} = props

	const { t } = useTranslation('common')
	const mobileUnitTilePrimaryKeyColumns = [
		{
			title: 'Text search',
			name: 'Text_search',
			hide: false,
		},
	]

	const [primaryKeyList, setPrimaryKeyList] = useState([])

	const { tileList, isLoading } = useTileQuery({
		dashboardKey,
		enableConfig: enabledFetchTileList,
	})

	const dataGridTile = useMemo(() => {
		const currentList =
			tileList?.length > 0 ? tileList?.filter((tile) => tile?.type === TYPES_OF_TILE.DATA_GRID) : []

		return unionBy(currentList, datagridListWhenCreating, 'name')
	}, [tileList, datagridListWhenCreating])

	useEffect(() => {
		if (filterData?.primaryKeyList?.length === 0 || !filterData?.primaryKeyList) {
			onFilterDataChange('primaryKeyList', ['Text_search'])
		}
		setPrimaryKeyList(['Text_search'])
	}, [filterData?.primaryKeyList])

	return (
		<Stack spacing={2} >
			<CheckboxList
				height ={32}
				title={t('common:dis.selectFields')}
				enableIcon
				enableCheckbox={false}
				//checkedIconList={filterData?.primaryKeyList}
				checkedIconList={primaryKeyList}
				data={mobileUnitTilePrimaryKeyColumns}
				onItemClick={() => { }}
				checkedList={[]}
				getLabel={(option) => option.title}
				getValue={(option) => option.name}
			/>
		</Stack>
	)
}

export default TextSearchForm
