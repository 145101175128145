import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Stack } from '@mui/material'
import CheckboxList from 'components/pages/dis-v2/_components/CheckboxList'

const masterDateTimeTilePrimaryKeyColumns = [
    {
        title: 'Master DateTime',
        name: 'Master_DateTime',
        hide: false,
        selectableTypes: ['DATE', 'DATETIME'],
    },
]
const DEFAULT_PRIMARY_KEYS = ['Master_DateTime']

export default function MasterDateTimeForm(props) {
    const {
        filterData,
        onFilterDataChange,
    } = props

    const { t } = useTranslation('common')

    useEffect(() => {
        if (isEmpty(filterData?.primaryKeyList)) {
            onFilterDataChange('primaryKeyList', DEFAULT_PRIMARY_KEYS)
        }
    }, [filterData?.primaryKeyList])

    return (
        <Stack spacing={2}>
            <CheckboxList
                title={t('common:dis.selectFields')}
                enableIcon
                enableCheckbox={false}
                //checkedIconList={filterData?.primaryKeyList}
                checkedIconList={DEFAULT_PRIMARY_KEYS}
                data={masterDateTimeTilePrimaryKeyColumns}
                onItemClick={() => { }}
                checkedList={[]}
                getLabel={(option) => option.title}
                getValue={(option) => option.name}
            />
        </Stack>
    )
}