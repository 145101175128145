import { Fragment, useState, useCallback, useRef, useEffect, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isEqual } from 'lodash'
import { enUS } from 'date-fns/locale'
import moment from 'moment'

import { Stack, TextField, MenuItem, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { IconButton, FormControl, Box } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro'
import { CalendarMonthOutlined } from '@mui/icons-material'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'

import { datesValues, operatorOptions } from '../../../../../utils/functions/conditionsOptions'
import { computeDateValue } from '../../../../../utils/functions/doformsDateUtil'
import { DATE_CONDITION_FILTER_TYPE } from './MasterDatetimeTile'

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '300px',
    },

    columnBox: {
        maxWidth: '15em',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '0',
            },
        },
    },

    filtersContainer: {
        margin: '16px',
    },

    footer: {
        // marginTop: theme.spacing(1),
        display: 'flex',
        minHeight: '52px',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiButton-root': {
            textTransform: 'none !important',
        },
    },

    footerLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '8px',
    },

    footerRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    formRoot: {
        padding: '16px',
        justifyContent: 'space-around',
    },

    menuItem: {
        fontSize: '13px',
        paddingTop: 'none',
        paddingBottom: 'none',
    },

    subheader: {
        margin: 'none',
    },

    operatorBox: {
        minWidth: '120px',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },

    customValueBox: {
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },

        '& .MuiOutlinedInput-input': {
            minHeight: '1.65em',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: '400',
            fontSize: '0.875rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
        },
    },

    presetsValueBox: {
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0 4px 4px 0`,
            },
        },
    },

    dateRangePicker: {
        width: '100%',
        display: 'inline-flex',
    },
}))

const DisplayDatePickerType = {
    SINGLE: 'single',
    RANGE: 'range',
}

function buildCondition(operator, initValues) {
    const isOperatorType = ['EQ', 'NE', 'BT', 'CT', 'EMPTY', 'NOTEMPTY'].includes(operator)
    if (isOperatorType) {
        switch (operator) {
            case 'EMPTY':
                return { preset: operator, type: 'EQ', values: [] }
            case 'NOTEMPTY':
                return { preset: operator, type: 'NE', values: [] }

            case 'BT':
                return {
                    type: 'BT',
                    join: 'AND',
                    values: [initValues[0] || null, initValues[1] || null],
                }
            default:
                return {
                    type: operator,
                    values: initValues ? [initValues[0]] : [null],
                }
        }
    } else {
        return { type: 'EQ', preset: operator, values: initValues || [] }
    }
}

const DEFAULT_OPERATOR = 'TODAY'

export default function MasterDateTimeSelectComponent(props) {
    const { dateConditionFilterDispatch } = props
    const [t] = useTranslation('common')
    const classes = useStyles()
    const operators = operatorOptions(t)
    const presets = datesValues(t)
    const [operatorValue, setOperatorValue] = useState(DEFAULT_OPERATOR)

    const handleDateOperatorChange = (event) => {
        const value = event.target.value
        setOperatorValue(value)
    }

    return <Grid
        container
        spacing={2}
    >
        <Grid item xs={4}>
            <TextField
                id="operatorsSelection"
                className={classes.operatorBox}
                size="small"
                select={true}
                label={t('common:filters.operator')}
                color="primary"
                variant="outlined"
                value={operatorValue}
                // defaultValue={masterDateTimeOperatorSettings?.type || masterDateTimeOperatorSettings?.name}
                onChange={(e) => {
                    const newOperator = e.target.value
                    setOperatorValue(newOperator)
                    handleDateOperatorChange(e)
                }}
            >
                {operators.map((option) => (
                    <MenuItem key={option.name} value={option.type} className={classes.menuItem}>
                        {option.name}
                    </MenuItem>
                ))}
                <hr className={classes.subheader} />
                {presets.map((option) => (
                    <MenuItem key={option.preset} value={option.preset} className={classes.menuItem}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
        <Grid item xs={6}>
            <DateTimePicker operatorValue={operatorValue} dateConditionFilterDispatch={dateConditionFilterDispatch} />
        </Grid>
    </Grid>
}

function DateTimePicker(props) {
    const { operatorValue, dateConditionFilterDispatch } = props
    const [t] = useTranslation('common')
    const dateValueRef = useRef(null)
    const classes = useStyles()
    const presets = datesValues(t)

    const [locale, setLocale] = useState(enUS)
    const [isOpenDate, setIsOpenDate] = useState(false)
    const [displayType, setDisplayType] = useState(null)
    const [values, setValues] = useState([])

    const isPreset = useMemo(() => {
        return presets?.some(item => item.preset === operatorValue)
    }, [operatorValue])

    useEffect(() => {
        const importLocaleFile = async () => {
            const localeToSet = await import(
                `date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
            )
            setLocale(localeToSet.default)
        }

        if (locale.code !== t('common:languages.dateFnsLocale')) {
            importLocaleFile()
        }
    }, [t('common:languages.dateFnsLocale')])


    useEffect(() => {
        const currentCondition = buildCondition(operatorValue, [])

        if (!currentCondition) return

        const { type: operator, preset } = currentCondition
        if (!operator && !preset) return

        if (preset) {
            const values = computeDateValue(preset)
            switch (preset) {
                case 'EMPTY':
                case 'NOTEMPTY':
                case 'ALL':
                    {
                        dateConditionFilterDispatch({
                            type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                            value: {
                                ...currentCondition,
                                values: []
                            }

                        })
                        setDisplayType(null)
                        setValues(values)
                        return
                    }
                case 'TODAY':
                case 'YESTERDAY':
                case 'TOMORROW': {
                    dateConditionFilterDispatch({
                        type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                        value: {
                            ...currentCondition,
                            values: values
                        }

                    })
                    setDisplayType(DisplayDatePickerType.SINGLE)
                    setValues(values)
                    return
                }

                default: {
                    dateConditionFilterDispatch({
                        type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                        value: {
                            ...currentCondition,
                            values: values
                        }

                    })
                    setDisplayType(DisplayDatePickerType.RANGE)
                    setValues(values)
                    return
                }
            }
        } else {
            switch (operator) {
                case 'EQ':
                case 'NE':
                case 'CT':
                    {
                        const values = [new Date()]
                        dateConditionFilterDispatch({
                            type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                            value: {
                                ...currentCondition,
                                values
                            }

                        })
                        setDisplayType(DisplayDatePickerType.SINGLE)
                        setValues(values)
                        return
                    }
                case 'BT':
                    {
                        const values = [new Date(), new Date()]
                        dateConditionFilterDispatch({
                            type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                            value: {
                                ...currentCondition,
                                values
                            }

                        })
                        setDisplayType(DisplayDatePickerType.RANGE)
                        setValues(values)
                        return
                    }

                default:
                    {
                        const values = []
                        dateConditionFilterDispatch({
                            type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                            value: {
                                ...currentCondition,
                                values
                            }

                        })
                        setDisplayType(null)
                        setValues(values)
                        return
                    }
            }
        }
    }, [operatorValue])

    const handleChangeSingleDatePicker = (newValue, isOnInput) => {
        dateConditionFilterDispatch({
            type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
            value: [newValue]
        })
        setValues([newValue])
    }

    const handleChangeDateRangePicker = (values) => {
        let startValue
        let endValue
        let startMomentObj = moment(values[0])
        let endMomentObj = moment(values[1])
        if (startMomentObj.isValid()) {
            startValue = startMomentObj.format('YYYY-MM-DD')
        }
        if (endMomentObj.isValid()) {
            endValue = endMomentObj.format('YYYY-MM-DD')
        }
        if (startValue && endValue) {
            dateConditionFilterDispatch({
                type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
                value: values
            })
            setValues(values)
        }
    }

    return <div>
        {(displayType === DisplayDatePickerType.SINGLE) && <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
            <DatePicker
                label={t('common:filters.date')}
                value={values}
                disabled={isPreset}
                onChange={(newValue) => handleChangeSingleDatePicker(newValue, false)}
                onOpen={() => setIsOpenDate(true)}
                onClose={() => setIsOpenDate(false)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={(e) => handleChangeSingleDatePicker(e.target.value, true)}
                        inputRef={dateValueRef}
                        className={classes.customValueBox}
                        size="small"
                        variant="outlined"
                        label={t('common:filters.value')}
                    ></TextField>
                )}
            />
        </LocalizationProvider>}
        {(displayType === DisplayDatePickerType.RANGE) && <div id="dateRangePicker" className={classes.dateRangePicker}>
            <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
                <MobileDateRangePicker
                    value={values}
                    disabled={isPreset}
                    onChange={(newValue) => handleChangeDateRangePicker(newValue)}
                    renderInput={(startProps, endProps) => (
                        <Fragment>
                            <TextField
                                {...startProps}
                                label={t('common:filters.from')}
                                // defaultValue={dateRangeValues[0]}
                                className={classes.customValueBox}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />

                            <TextField
                                {...endProps}
                                label={t('common:filters.to')}
                                // defaultValue={dateRangeValues[1]}
                                className={classes.customValueBox}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </Fragment>
                    )}
                />
            </LocalizationProvider>
        </div>}
    </div>


}