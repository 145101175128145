import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { IconButton, Tooltip, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function CheckboxList({
	enableIcon = false,
	enableCheckbox = true,
	onIconClick,
	checkedIconList,
	title,
	data,
	onItemClick,
	checkedList,
	getLabel,
	getValue,
	checkboxColor = 'primary',
	height,
}) {
	const [t] = useTranslation('common')
	const [heightValue, setHeightValue] = useState(180)

	useEffect(() => {
		setHeightValue(height)
	}, [height])

	return (
		<>
			<Typography variant="subtitle2" sx={{ pl: 1 }}>
				{title}
			</Typography>
			<List
				sx={{
					width: '100%',
					minHeight: {heightValue},
					maxHeight: {heightValue},
					overflowY: 'scroll',
					border: '1px solid #ddd',
					borderRadius: 1,
					mb: 2,
				}}
				dense
			>
				{data?.length === 0 && <Typography px={2}>{t('common:dis.emptyFields')}</Typography>}
				{data?.length > 0 &&
					data.map((option) => {
						if (option && option?.hide) {
							return null
						}

						const labelId = `checkbox-list-label-${option.value}`
						const optionValue = getValue(option)

						return (
							<ListItem
								key={optionValue}
								disablePadding
								sx={{
									'& .MuiButtonBase-root': {
										py: '2px',
									},
								}}
							>
								<ListItemButton
									role={undefined}
									onClick={onItemClick(optionValue)}
									dense
									sx={{
										'& .MuiListItemIcon-root': {
											minWidth: '15px !important',
										},
									}}
								>
									{enableIcon && (
										<Tooltip title={t('common:dis.makeKey')} placement="right">
											<IconButton
												edge="start"
												aria-label="comments"
												size="small"
												sx={{ mr: 1 }}
												onClick={(e) => {
													e.stopPropagation()

													onIconClick?.(optionValue)
												}}
											>
												<VpnKeyIcon
													fontSize="small"
													color={
														checkedIconList?.indexOf(optionValue) !== -1 ? 'primary' : 'inherit'
													}
												/>
											</IconButton>
										</Tooltip>
									)}
									<ListItemText id={labelId} primary={getLabel(option)} />
									{enableCheckbox && (
										<ListItemIcon>
											<Checkbox
												edge="end"
												checked={checkedList?.indexOf(optionValue) !== -1}
												tabIndex={-1}
												disableRipple
												color={checkboxColor}
												inputProps={{
													'aria-labelledby': labelId,
												}}
												size="small"
											/>
										</ListItemIcon>
									)}
								</ListItemButton>
							</ListItem>
						)
					})}
			</List>
		</>
	)
}
