import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _, { isEmpty, isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
	FormControl,
	Stack,
	TextField,
	Popover,
	List,
	ListItemButton,
	ListItemText,
	Typography,
	Collapse,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Box,
} from '@mui/material'

import { getAllProjects, getProjectForms } from '../../../../data/dataServices'
import LoadingSpinner from 'custom-components/LoadingSpinner'
import { performFormAction } from '../../../../data/datagrid/recordsHelper'
import OtherOptions, {
	DEFAULT_CHECKED_OPTIONS,
} from 'components/pages/dis-v2/_components/OtherOptions'
import { DateInputValue } from 'components/data/dataHelpers'
export const DEFAULT_DOFORM_CHECKED_OPTIONS = [
	'showSubmit',
	'showCancel',
	'showTileLabel',
	'refreshOnSubmit',
	'showInDashboard',
]
const initValues = {
	projectKey: null,
	projectName: '',
	formKey: null,
	formName: '',
}

const DoFormsForm = ({
	dashboardKey,
	filterData,
	enabledFetchTileList = true,
	datagridListWhenCreating = [],
	onFilterDataChange,
	isDialog,
	allProjectForms,
	setAllProjectForms,
}) => {
	const { t } = useTranslation('common')
	const environment = useSelector((state) => state.environment)

	const [loading, setLoading] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [projectForms, setProjectForms] = useState([])
	const [collapseProjects, setCollapseProjects] = useState([])
	const [defaultProjectForm, setDefaultProjectForm] = useState(initValues)
	let listProjectFrom = []

	const OTHER_OPTIONS = [
		{ label: t('common:chart.showSubmit'), value: 'showSubmit' },
		{
			label: t('common:chart.showCancel'),
			value: 'showCancel',
		},
		{ label: t('common:chart.showTileLabel'), value: 'showTileLabel' },
		{
			label: t('common:chart.refreshOnSubmit'),
			value: 'refreshOnSubmit',
		},
		{
			label: t('common:chart.showInDashboard'),
			value: 'showInDashboard',
		},
	]

	useEffect(() => {
		if (allProjectForms?.length > 0) {
			listProjectFrom = allProjectForms
			setProjectForms(allProjectForms)
		}
	}, [allProjectForms])

	useEffect(() => {
		if (filterData?.defaultProjectForm?.projectKey && filterData?.defaultProjectForm?.formKey) {
			setDefaultProjectForm(filterData?.defaultProjectForm)
		}
	}, [filterData?.defaultProjectForm])

	useEffect(() => {
		if (
			(isEqual(filterData?.otherOptionsChecked, DEFAULT_CHECKED_OPTIONS) &&
				filterData?.otherOptionsChecked) ||
			filterData?.otherOptionsChecked === undefined
		) {
			filterData.otherOptionsChecked = DEFAULT_DOFORM_CHECKED_OPTIONS
		}
	}, [filterData?.otherOptionsChecked])

	const isSelectOpen = useMemo(() => {
		return Boolean(anchorEl)
	}, [anchorEl])

	const handleSelectOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleSelectClose = () => {
		setAnchorEl(null)
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const loadFormsByProject = async (projects) => {
		let promises = []
		for (let i = 0; i < projects.length; i++) {
			const promise = getProjectForms(projects[i].key, environment.apiToken).then((resp) => {
				let promiseObject = { key: projects[i].key, name: projects[i].name, forms: [] }
				if (resp?.data && _.isArray(resp.data) && resp.data.length) {
					promiseObject.forms = _.sortBy(resp.data, 'name')
				}
				return promiseObject
			})
			promises.push(promise)
		}

		return await Promise.all(promises)
	}

	const initiateLoadFormsByProject = (projects) => {
		loadFormsByProject(projects)
			.then((res) => {
				const newProjects = _.sortBy(res, 'name')
				setProjectForms(newProjects)
				setAllProjectForms(newProjects)
			})
			.catch((err) => {})
			.finally(() => {
				setLoading(false)
			})
	}

	const initiateLoadAllProjects = () => {
		if (projectForms?.length === 0) {
			setLoading(true)
			loadAllProjects()
				.then((res) => {
					const projects = _.sortBy(res.data, 'name')
					initiateLoadFormsByProject(projects)
				})
				.catch((err) => {})
				.finally(() => {})
		}
	}

	const formattedOtherOptions = OTHER_OPTIONS.map((item) => {
		if (item.value !== 'enableActiveUpdate') {
			return { ...item, hide: false }
		}
		return item
	})

	useEffect(() => {
		;(async () => {
			try {
				if (listProjectFrom?.length === 0) {
					initiateLoadAllProjects()
				}
			} catch (err) {
				console.log(err)
			}
		})()
	}, [])

	const handleCollapsed = (projectKey) => {
		const found = collapseProjects.includes(projectKey)
		if (!found) {
			setCollapseProjects([...collapseProjects, projectKey])
		} else {
			setCollapseProjects(collapseProjects.filter((item) => item !== projectKey))
		}
	}

	const handleFormSelection = (project, form) => {
		const projectKey = project.key
		const formKey = form.key

		setDefaultProjectForm({
			...defaultProjectForm,
			projectKey: projectKey,
			formKey: formKey,
			formName: form.name,
			projectName: project.name,
		})
		onFilterDataChange('defaultProjectForm', {
			projectKey: projectKey,
			formKey: formKey,
			formName: form.name,
			projectName: project.name,
		})
		const projectSelected = projectForms.find((project) => project.key === projectKey)
		if (projectSelected) {
			const formSelected = projectSelected?.forms?.find((form) => form.key === formKey)

			performFormAction(environment, { ...formSelected, projectKey }, 'add', 'other')
				.then((res) => {
					onFilterDataChange('defaultUrl', res)
					handleSelectClose()
				})
				.catch((err) => {
					handleSelectClose()
				})
		}
	}

	const showFieldForDevices = () => {
		let projectName = ''
		let formName = ''

		projectName = defaultProjectForm.projectName ? defaultProjectForm.projectName : ''

		formName = defaultProjectForm.formName ? defaultProjectForm.formName : ''
		return (
			<Box spacing={2} sx={{ width: isDialog === true ? '100% !important' : '50% !important' }}>
				<TextField
					id="select-default-form"
					size="small"
					onClick={handleSelectOpen}
					value={projectName + ' / ' + formName}
					label={t('common:input.selectForm')}
					fullWidth
					InputProps={{
						readOnly: true,
						endAdornment: isSelectOpen ? (
							<>
								{loading ? (
									<LoadingSpinner withStyle={false} size={15} />
								) : (
									<ArrowDropUp position="end" />
								)}
							</>
						) : (
							<>
								{loading ? (
									<LoadingSpinner withStyle={false} size={15} />
								) : (
									<ArrowDropDown position="end" />
								)}
							</>
						),
					}}
				/>
			</Box>
		)
	}

	const handleChangeValue = (value) => {
		onFilterDataChange('otherOptionsChecked', value)
	}

	return (
		<Stack sx={{ width: '100% !important' }} spacing={2}>
			{showFieldForDevices()}

			{
				<Box spacing={2} sx={{ width: isDialog === true ? '100% !important' : '50% !important' }}>
					<Popover
						anchorEl={anchorEl}
						id={'my-default-form-container'}
						//keepMounted
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={isSelectOpen}
						onClose={handleSelectClose}
						PaperProps={{
							style: { width: '400px', maxWidth: '45vw', maxHeight: '60vh' },
						}}
					>
						<List
							component="nav"
							dense={true}
							aria-labelledby="nested-list-subheader"
							onMouseDown={(event) => event.stopPropagation()}
						>
							{projectForms?.map((project, index) => (
								<div key={project.key}>
									<ListItemButton
										sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
										onClick={() => handleCollapsed(project.key)}
									>
										<ListItemText>
											<Typography variant={'h6'} fontSize={16}>
												{project.name}
											</Typography>
										</ListItemText>
										{!collapseProjects.includes(project.key) ? <ArrowDropUp /> : <ArrowDropDown />}
									</ListItemButton>
									<Collapse
										id={index}
										in={!collapseProjects.includes(project.key)}
										timeout="auto"
										unmountOnExit
									>
										{project?.forms?.map((form) => {
											return (
												<ListItemButton
													key={form.key}
													onClick={() => handleFormSelection(project, form)}
												>
													<ListItemText>
														<Typography variant={'body1'} fontSize={14}>
															{form.name}
														</Typography>
													</ListItemText>
												</ListItemButton>
											)
										})}
									</Collapse>
								</div>
							))}
						</List>
					</Popover>
				</Box>
			}

			<Box spacing={2} sx={{ width: '100% !important' }}>
				<OtherOptions
					optionList={formattedOtherOptions}
					checkedOptions={filterData?.otherOptionsChecked}
					onChangeOptions={handleChangeValue}
					// onChangeOptions={(value) => {
					// 	let a = tilePositionList
					// 	onFilterDataChange('otherOptionsChecked', value)
					// }}
				/>
			</Box>
		</Stack>
	)
}
export default DoFormsForm
